import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout >
    <SEO title="Home" keywords={[`Food`, `Beverage`, `Golf`, `restaurants`]} />
  
  </Layout>
)

export default IndexPage
